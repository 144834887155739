.OrderPage{
    width: 100%;
}

.OrderCard{
    height: 100%;
}

.OrderDivider{
    min-height: 0.125rem;
}

.NumNotaFiscal{
    margin-left: 50px;
}

.CorreiosLogo{
    max-width: 200px;
}

.QRCode{
    width: 100px;
    height: 100px;
}