@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&family=Poppins:wght@700&display=swap');
.Sidebar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 28px;
    gap: 50px;

    position: absolute;
    width: 280px;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #191919;
}

.SidebarTitle{
    width: 224px;
    height: 32px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;

    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.08em;

    /* White */
    color: #FFFFFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.PageButton{
    font-family: 'Inter';
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    width: 224px;
    height: 48px;

    border-radius: 4px;
}

.PagesList{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    align-self: flex-start;
    margin: 50px 0px;
    width: 224px;
    height: 264px;
}

.SelectedPageButton{
    font-family: 'Inter';
    color: #FFFFFF;
    background-color: #794396;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    width: 224px;
    height: 48px;

    border-radius: 4px;
}

.SidebarIcon{
    width: 25px;
    height: 25px;
}