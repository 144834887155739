.UploadIcon {
    max-height: 100px;
    max-width: 100px;
    color: #878787;
}

.CreateOrderBtn{
    background-color: #794396;
    border-color: #794396;
}

.UploadTitle{
    font-weight: 700;
    font-family: 'Inter';
}

.DropzoneContainer{
    border-color: #878787;
    border-radius: 10px;
    border-style: dashed;
}

.Page{
    display: flex;
}

.Content{
    margin-left: 280px;
    width: 100vw;
    background-color: #F4F5F7;
    height: 100vh;
    overflow-y: scroll;
}

.OpsViews{
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.SelectedTab{
    color: #794396;
    font-weight: 700;
    font-family: 'Inter';
    border-bottom-color: #794396;
}

.OtherTab{
    color: gray;
    font-weight: 700;
    font-family: 'Inter';
}