.UploadTitle{
    font-weight: 700;
    font-family: 'Inter';
}

.UploadIcon {
    max-height: 100px;
    max-width: 100px;
    color: #878787;
}

.CreateOrderBtn{
    background-color: #794396;
    border-color: #794396;
}